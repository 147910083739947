.react-flow__node {
    border: 0;
    border-radius: 0;
    padding: 0;
    width: unset;
    background-color: transparent;
}

.react-flow__node-default.selectable.selected,
.react-flow__node-default.selectable:focus {
    box-shadow: 0px 0px 5px 0.5px #a3a3a3;
}

.diagram-editor {
    display: flex;
    position: relative;
    height: 100vh;
}

.diagram-editor * {
    box-sizing: border-box;
}

/* Board */
.diagram-editor>.board {
    flex: 1;
    height: 100%;
    border: 1px solid #79aaff;
    border-color: var(--editor-color-primary);
}

.diagram-editor>.board svg {}

.board .element-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.board .element-item.as-shape {
    border: 2px solid black;
    background-color: white;
}

.board .element-item>.shape {
    position: absolute;
    width: 100%;
    height: 100%;
    /* margin: 0 auto; */
    text-align: left;
}


.board .element-item>.shape>svg {
    position: relative;
    width: 100%;
    height: 100%;
    stroke: black;
    stroke-width: 2px;
    fill: white;
}

.board .element-item>.shape {
    width: 100%;
    height: 100%;
}

.board .element-item>.label {
    /* padding: 2px 5px; */
    border-radius: 4px;
    /* background-color: white; */
    z-index: 1;
    color: blue;
    color: var(--editor-color-primary);
    pointer-events: none;
    font-size: 10px;
}

.board .element-item>.icon {
    position: absolute;
    width: 80%;
    height: 80%;
}

.board .element-item .icon-element-settings {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 16px;
    height: 16px;
    opacity: 0;
}

.board .element-item:hover .icon-element-settings {
    opacity: 1;
    cursor: pointer;
}

/* Board Panels */
.panel-variants,
.panel-controls {
    position: absolute;
    display: flex;
    gap: 8px;
}

.panel-variants>*,
.panel-controls>* {
    padding: 2px 5px;
}

.panel-controls {
    left: 220px;
}


/* Left panel Library */
.diagram-editor .left-panel {
    border-top: 1px solid #79aaff;
    border-color: var(--editor-color-primary);
}

.diagram-editor .library-panel {
    height: calc(100% - 46px);
    overflow-y: auto;
    width: 210px;
    background-color: #EBF2FE;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
}

.diagram-editor .library-panel::-webkit-scrollbar {
    width: 0;
}

.library-panel>.title {
    font-weight: 700;
    background-color: #2F6CF6;
    background-color: var(--editor-color-primary);
    border: 1px solid white;
    padding: 2px 5px;
    color: white;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
}

.library-panel>.list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow: auto;
    max-height: 0px;
    transition: max-height 0.3s;
    border-bottom: 1px solid #2F6CF6;
    border-color: var(--editor-color-primary);
    background-color: white;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
}

.library-panel>.list::-webkit-scrollbar {
    width: 0px;
}

.library-panel>.list.show {
    max-height: 600px;
}

.library-panel>.list>div {
    cursor: pointer;
    width: 100px;
    min-height: 80px;
    text-align: center;
    padding: 5px 0;

    transition: all 0.3s;
}

.library-panel>.list>div:hover {
    background-color: rgba(146, 146, 146, 0.5);
}

/* Share component */
.library-panel .component-item {}

.library-panel .component-item>.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    text-align: left;
}

.library-panel .component-item>.icon>*:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    /* top: 0;
    bottom: 0; */
    left: 0;
    right: 0;
    /* margin: 0 auto;
    text-align: left; */
}

.library-panel .component-item>.icon>svg {
    position: relative;
    width: 100%;
    height: 100%;
    stroke: black;
    stroke-width: 2px;
    fill: white;
}

.library-panel .component-item>.icon>img {
    width: 80%;
    height: 80%;
}

.library-panel .component-item>.label {
    color: blue;
    color: var(--editor-color-primary);
}

/* .library-panel>.list>div svg {
    stroke: black;
    stroke-width: 2px;
} */

.icon-default {
    border: 1px solid black;
    margin: 0 auto;
    height: 100%;
}




/* Details panel */
.diagram-editor .details-panel {}

.diagram-editor .panel-node-details {}

.diagram-editor .panel-node-details .MuiTextField-root {
    width: 100%;
}

.panel-node-details .panel-accordion {}

.panel-node-details .panel-accordion .panel-accordion-label {
    font-weight: 700;
    background-color: #2F6CF6;
    background-color: var(--editor-color-primary);
    border: 1px solid white;
    padding: 2px 5px;
    color: white;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;
}

.panel-node-details .panel-accordion .panel-accordion-content {
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
    max-height: 0vh;
    transition: padding-top 0.3s, padding-bottom 0.3s, max-height 0.3s, opacity 0.3s;
    overflow: hidden;
}

.panel-node-details .panel-accordion .panel-accordion-content.open {
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 1;
    max-height: 100vh;
}

.control-borders {
    position: relative;
}

.control-borders-label {
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: black;
    color: var(--editor-color-primary);
    text-align: center;
}

.borders-body {
    position: relative;
    height: 100px;
    --offset: 10px;
}

.borders-body .borders-top,
.borders-body .borders-right,
.borders-body .borders-bottom,
.borders-body .borders-left {
    position: absolute;
    /* margin: 1px; */
    border: 1px solid black;
    border-color: var(--editor-color-primary);
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
}

.borders-body .active {
    background-color: black;
    background-color: var(--editor-color-primary);
}

.borders-body .borders-top {
    left: var(--offset);
    right: var(--offset);
    top: 0;
}

.borders-body .borders-right {
    top: var(--offset);
    bottom: var(--offset);
    right: 0;
}

.borders-body .borders-bottom {
    left: var(--offset);
    right: var(--offset);
    bottom: 0;
}

.borders-body .borders-left {
    top: var(--offset);
    bottom: var(--offset);
    left: 0;
}